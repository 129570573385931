<template>
  <div>
    <form method="post" @submit="handleSubmit">
      <dx-util-form
        ref="registerFormRef"
        :form-data="registerFormData"
        :col-count="1"
        :show-colon-after-label="true"
        label-location="top"
      >
        <template #terms-template="{}">
          <div class="d-flex justify-content-end">
            <b-link @click="popupShow">
              Privacy Policy & Terms
            </b-link>
          </div>
        </template>
        <template #error-template>
          <b-alert show variant="danger">
            <div class="alert-body">
              <div class="d-flex flex-row justify-content-center">
              <feather-icon
                class="mr-25 align-self-center"
                icon="AlertTriangleIcon"
                size="80"
              />
              <span class="ml-25 align-self-center">{{ errorMessage }}</span>
              </div>

            </div>
          </b-alert>
        </template>
        <dx-util-group-item :col-count="3">
          <dx-util-item :editor-options="textFieldOptions" data-field="firstName" editor-type="dxTextBox">
            <dx-util-required-rule message="First Name is required" />
          </dx-util-item>
          <dx-util-item :editor-options="textFieldOptions" data-field="middleName" />
          <dx-util-item :editor-options="textFieldOptions" data-field="lastName" editor-type="dxTextBox">
            <dx-util-required-rule message="Last Name is required" />
          </dx-util-item>
        </dx-util-group-item>
        <dx-util-item :editor-options="usernameOptions" data-field="username" editor-type="dxTextBox">
          <dx-util-required-rule message="Username is required" />
          <dx-util-pattern-rule
            :pattern="usernamePattern"
            message="Username consists of alphanumeric characters (a-zA-Z0-9), lowercase, or uppercase.
                    Username allowed of the dot (.), underscore (_), and hyphen (-).
                    The dot (.), underscore (_), or hyphen (-) must not be the first or last character.
                    The dot (.), underscore (_), or hyphen (-) does not appear consecutively, e.g., user..name
                    The number of characters must be between 5 to 20."
          />
        </dx-util-item>
        <dx-util-item :editor-options="emailOptions" data-field="email">
          <dx-util-required-rule message="Email is required" />
          <dx-util-email-rule message="Email is invalid" />
        </dx-util-item>
        <dx-util-group-item :col-count="2">
          <dx-util-item :editor-options="passwordOptions" data-field="password">
            <dx-util-required-rule message="Password is required" />
            <dx-util-pattern-rule
              :pattern="passwordPattern"
              message="Your password must contain at least one uppercase, one lowercase, one special character and one digit"
            />
          </dx-util-item>
          <dx-util-item :editor-options="passwordOptions" data-field="confirmPassword">
            <dx-util-required-rule message="Confirm Password is required" />
            <dx-util-compare-rule :comparison-target="passwordComparison" message="Password and Confirm Password do not match" />
          </dx-util-item>
        </dx-util-group-item>
        <dx-util-group-item :col-count="2">
          <dx-util-item :editor-options="agrementApprovedOptions" data-field="agrementApproved" editor-type="dxCheckBox" :label="{visible: false}">
            <dx-util-compare-rule
              :comparison-target="agrementApprovedComparison"
              type="compare"
              message="You must agree to the Privacy Policy and Terms"
            />
          </dx-util-item>
          <dx-util-simple-item template="terms-template" horizontal-alignment="right" />
        </dx-util-group-item>
        <dx-util-button-item
            :button-options="saveButtonOptions"
            horizontal-alignment="right"
          />
        <dx-util-group-item :col-count="1" :visible="isVisibleErrorMessages">
              <dx-util-simple-item template="error-template" horizontal-alignment="right" />
        </dx-util-group-item>
      </dx-util-form>
    </form>
    <div v-if="isCreated">
      <div class="d-flex flex-column align-items-center border rounded py-1 mt-1">
        <div class="d-flex justify-content-center">
          <b-avatar :text="time(seconds)" variant="success" />
        </div>
        <div class="d-flex justify-content-center">
          <div class="px-1">
            <span class="send-message">
              An activation link has been sent to your email address.
              Please click activation link to activate your account.
              If you do not see the activation mail, please check your spam folder.
            </span>
            <span class="resend-message">
              If you have not received the activation link, please click on resend button. Please also check your spam folder.
            </span>
          </div>
        </div>
        <div id="resendBtn" class="d-flex justify-content-center">
          <dx-util-button
            id="btnResend"
            type="success"
            styling-mode="contained"
            :height="36"
            :disabled="!btnResendDisable"
            @click="onClickResend"
          >
            <template #content>
              <span>
                <dx-util-load-indicator
                  :visible="loadIndicatorVisible"
                  :indicator-src="`${require('@/assets/images/svg/bars.svg')}`"
                  class="button-indicator"
                />
                <span class="dx-button-text">{{ resendButtontText }}</span>
              </span>
            </template>
            </dx-util-button>
        </div>
      </div>
    </div>
    <dx-util-popup
      :visible="popupVisible"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="true"
      content-template="popup-content"
      title="Privacy Policy & Terms"
      @hidden="popupHidden"
    >
      <dx-util-position at="center" my="center" />
      <template #popup-content>
        <dx-util-scroll-view height="100%" width="100%">
          <terms-privacy />
        </dx-util-scroll-view>
      </template>
    </dx-util-popup>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import userAccountService from '@/http/requests/system/userAccountService'
import { Notify } from '@robustshell/utils/index'
import TermsPrivacy from './components/TermsPrivacy.vue'

const maxValue = 60

export default {
  components: {
    TermsPrivacy,
  },
  props: {
    tenantApiKey: {
      type: String,
      required: true,
    },
    registerStoreUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popupVisible: false,
      isCreated: false,
      registerFormData: {
        firstName: '',
        middleName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        agrementApproved: false,
      },
      usernamePattern: /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/,
      passwordPattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/,
      maxValue,
      seconds: maxValue,
      inProgress: false,
      resendButtontText: 'Resend Activation Link',
      btnResendDisable: false,
      loadIndicatorVisible: false,
      errorMessage: '',
    }
  },
  computed: {
    isVisibleErrorMessages() {
      return (this.errorMessage !== '')
    },
    textFieldOptions() {
      return {
        mode: 'text',
        readOnly: this.isCreated,
        inputAttr: { style: 'text-transform: capitalize' },
      }
    },
    usernameOptions() {
      return {
        mode: 'text',
        showClearButton: true,
        readOnly: this.isCreated,
        onValueChanged: e => {
          if (e.value === '' || (e.previousValue !== '' && e.value !== e.previousValue)) {
            this.errorMessage = ''
          }
        },
      }
    },
    passwordOptions() {
      return {
        mode: 'password',
        readOnly: this.isCreated,
      }
    },
    emailOptions() {
      return {
        mode: 'email',
        readOnly: this.isCreated,
        inputAttr: { style: 'text-transform: lowercase' },
        onValueChanged: e => {
          if (e.value === '' || (e.previousValue !== '' && e.value !== e.previousValue)) {
            this.errorMessage = ''
          }
        },
      }
    },
    agrementApprovedOptions() {
      return {
        text: 'I agree to the Privacy Policy and Terms',
        readOnly: this.isCreated,
      }
    },
    saveButtonOptions() {
      return {
        text: 'Register',
        type: 'success',
        disabled: this.isCreated,
        useSubmitBehavior: true,
      }
    },
    decodedRegisterStoreUrl() {
      return decodeURIComponent(this.registerStoreUrl)
    },
  },
  methods: {
    passwordComparison() {
      return this.registerFormData.password
    },
    agrementApprovedComparison() {
      return true
    },
    popupShow() {
      this.popupVisible = true
    },
    popupHidden(e) {
      this.popupVisible = false
    },
    handleSubmit(e) {
      e.preventDefault()
      const form = this.$refs.registerFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const user = {
          username: this.registerFormData.username,
          email: this.registerFormData.email,
          password: this.registerFormData.password,
          securityCode: '',
          firstName: this.registerFormData.firstName,
          middleName: this.registerFormData.middleName,
          lastName: this.registerFormData.lastName,
          agreementApproved: this.registerFormData.agrementApproved,
          tenantApiKey: this.tenantApiKey,
          registerStoreUrl: this.decodedRegisterStoreUrl,
        }
        userAccountService.register(user)
          .then(result => {
            if (result.statusText === 'Created' || result.status === 201) {
              this.isReadonly = true
              this.isCreated = true
              this.inProgress = !this.inProgress
              this.intervalId = setInterval(() => this.timer(), 1000)
              Notify.success('Your account has been succesfully created.')
            }
          }).catch(error => {
            if (error && error.data.meta) {
              if (error.data.meta.error_list.length) {
                let errors = ''
                error.data.meta.error_list.forEach(item => {
                  errors = `${errors} ${item.message} `
                })
                this.errorMessage = errors
              }
            }
          })
      }
    },
    onClickResend(e) {
      this.btnResendDisable = !this.btnResendDisable
      userAccountService.resendActivationCode(this.registerFormData.email).then(() => {
        Notify.success(`New activation link has been sent to ${this.registerFormData.email}.If you do not see the activation code, please check your spam folder`)
      }).finally(() => {
        if (this.seconds === 0) {
          this.seconds = maxValue
        }
        this.inProgress = !this.inProgress
        this.intervalId = setInterval(() => this.timer(), 1000)
      })
    },
    time(value) {
      return `${(`0${value}`).slice(-2)}`
    },
    timer() {
      this.seconds -= 1
      if (this.seconds === 0) {
        this.inProgress = !this.inProgress
        this.btnResendDisable = !this.btnResendDisable
        clearInterval(this.intervalId)
      }
    },
  },
}
</script>

<style lang="scss">
  .send-message {
    display: block;
    font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
    font-size: 1rem;
    color:#ee1111;
    text-align: justify;
    text-justify: inter-word;
  }
  .resend-message {
    display: block;
    font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
    font-size: 1rem;
    text-align: justify;
    text-justify: inter-word;
  }
  #resendBtn{
    .dx-button-content {
      padding: 4px 6px;
    }
    .dx-button-text {
      color: #ffffff;
    }
  }
</style>
