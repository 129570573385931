<template>
  <div>
    <!-- Top Info Banner -->
    <div class="mb-1">
      <div class="row justify-content-center px-4">
        <div v-if="companyRegistrationForm.tenantType === TenantTypeEnum.INDIVIDUAL.value" class="text-left">
          <img :src="require('@/assets/images/illustration/individual.svg')" height="40">
          <h4 class="m-0 text-warning">
            Individual Merchant Registration
          </h4>
        </div>
        <div v-if="companyRegistrationForm.tenantType === TenantTypeEnum.SERVING.value" class="text-left">
          <img :src="require('@/assets/images/illustration/service-provider.svg')" height="40">
          <h4 class="m-0 text-warning">
          Service Provider (3PL, Warehouse)
          </h4>
        </div>
      </div>
      <div class="mt-1 bg-success rounded p-1 text-black d-flex align-items-center">
        <p-icon name="bi-megaphone-fill" class="mx-1" size="32px" />
        <strong class="d-inline" style="font-size:18px">
          "30 Days Free Trial - No Credit Card required for Sign up"
        </strong>
      </div>
    </div>
  <div>
    <div v-show="currentStep === 1">
      <div>
        <register-form-plan-selection />
      </div>
      <div class="d-flex justify-content-between mt-1">
        <dx-util-button text="Back" type="danger" class="px-2" @click="goBackSelections" />
        <dx-util-button text="Next" type="success" class="px-2" @click="setPlanRegistration" />
      </div>
    </div>
    <div v-show="currentStep === 2">
      <register-form-company-info :check-form="checkCompanyForm" @emit-validation-result="setCompanyRegistration" />
      <div class="d-flex justify-content-between mt-1">
        <dx-util-button text="Back" type="danger" class="px-2" @click="prevStep(1)" />
        <dx-util-button text="Next" type="success" class="px-2" @click="checkCompanyRegistration" />
      </div>
    </div>
    <div v-show="currentStep === 3">
      <register-form-user-info :check-form="checkUserForm" @emit-validation-result="setUserRegistration" />
      <div class="d-flex justify-content-between mt-1">
        <dx-util-button text="Back" type="danger" class="px-1" @click="prevStep(2)" />
        <dx-util-button text="Next" type="success" class="px-1" @click="checkUserRegistration" />
      </div>
    </div>
    <div v-show="currentStep === 4">
      <register-form-check-out @emit-agreement-accepted="updateApprovalStatus" />
      <div class="d-flex justify-content-between mt-half">
        <dx-util-button text="Back" type="danger" class="px-1" @click="prevStep(3)" />
        <dx-util-button text="Submit" :disabled="submitDisabled" type="success" class="px-1" @click="submitForm" />
      </div>
    </div>
    <div v-show="currentStep === 5">
      <div class="d-flex flex-column align-items-center border rounded py-1 mt-1">
        <div class="d-flex justify-content-center">
          <b-avatar :text="time(seconds)" variant="success" />
        </div>
        <div class="d-flex justify-content-center">
          <div class="px-1">
            <div class="alert alert-danger m-1">
              An activation link has been sent to your email address.
              Please click activation link to activate your account.
              If you do not see the activation mail, please check your spam folder.
            </div>
            <div class="alert alert-success m-1">
              If you have not received the activation link, please click on resend button. Please also check your spam folder.
            </div>
          </div>
        </div>
        <div id="resendBtn" class="d-flex justify-content-center">
          <dx-util-button
            id="btnResend"
            type="success"
            styling-mode="contained"
            :height="36"
            :disabled="!btnResendDisable"
            @click="onClickResend"
          >
            <template #content>
              <span>
                <dx-util-load-indicator
                  :visible="loadIndicatorVisible"
                  :indicator-src="`${require('@/assets/images/svg/bars.svg')}`"
                  class="button-indicator"
                />
                <span class="dx-button-text">{{ resendButtontText }}</span>
              </span>
            </template>
            </dx-util-button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import userAccountService from '@/http/requests/system/userAccountService'
import tenantService from '@/http/requests/system/tenantService'
import TenantTypeEnum from '@/enums/tenantType.enum'
import { v4 as uuidv4 } from 'uuid'
import { useCustomerRegistration } from '@/views/pages/authentication/register-form/useCustomerRegistration'

const maxValue = 60
export default {
  components: {
    'register-form-plan-selection': () => import('./components/RegisterFormPlanSelection.vue'),
    'register-form-company-info': () => import('./components/RegisterFormCompanyInfo.vue'),
    'register-form-user-info': () => import('./components/RegisterFormUserInfo.vue'),
    'register-form-check-out': () => import('./components/RegisterFormCheckOut.vue'),
  },
  data() {
    return {
      TenantTypeEnum,
      step: 1,
      currentStep: 1,
      maxValue,
      inProgress: false,
      resendButtontText: 'Resend Activation Link',
      btnResendDisable: false,
      loadIndicatorVisible: false,
      errorMessage: '',
      seconds: maxValue,
      intervalId: '',
      readOnly: false,
      submitDisabled: false,
      checkCompanyForm: '',
      checkUserForm: '',
    }
  },
  computed: {
    today() {
      const date = new Date()
      const day = date.getDate().toString()
      const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date)
      return {
        day: day,
        month: month,
      }
    },
  },
  setup() {
    const { subscribedPlan, companyRegistrationForm, userRegistrationForm } = useCustomerRegistration()
    return {
      subscribedPlan,
      companyRegistrationForm,
      userRegistrationForm,
    }
  },
  methods: {
    prevStep(step) {
      this.currentStep = step
      if (this.currentStep === 0) {
        this.$emit('on-back-to-selections')
      }
    },
    setPlanRegistration() {
      if (this.subscribedPlan.planId === null) {
        Notify.warning('Please select a plan to continue.')
        return
      }
      this.currentStep += 1
    },
    checkUserRegistration() {
      this.checkUserForm = uuidv4()
    },
    setUserRegistration(validationResult) {
      // Submit form
      if (validationResult.isValid) {
        this.currentStep += 1
      }
    },
    checkCompanyRegistration() {
      this.checkCompanyForm = uuidv4()
    },
    setCompanyRegistration(validationResult) {
      if (validationResult.isValid) {
        this.currentStep += 1
      }
    },
    submitForm() {
      const registrationForm = { ...this.companyRegistrationForm, ...this.userRegistrationForm, subscribedPlan: this.subscribedPlan }
      // registrationForm.tenantType = this.tenantType.value
      registrationForm.tenantName = this.companyRegistrationForm.companyName
      delete registrationForm.passwordCheck
      tenantService.registerTenant(registrationForm).then(() => {
        this.currentStep += 1
        this.resetCompanyRegistrationForm()
        this.resetUserRegistrationForm()
        this.resetSubscribedPlan()
        this.intervalId = setInterval(() => this.timer(), 1000)
      })
    },
    goBackSelections() {
      this.$emit('on-back-to-selections')
    },
    onClickResend(e) {
      this.btnResendDisable = !this.btnResendDisable
      userAccountService.resendActivationCode(this.userRegistrationForm.email).then(() => {
        Notify.success(`New activation link has been sent to ${this.userRegistrationForm.email}. If you do not see the activation code, please check your spam folder`)
      }).finally(() => {
        if (this.seconds === 0) {
          this.seconds = maxValue
        }
        this.inProgress = !this.inProgress
        this.intervalId = setInterval(() => this.timer(), 1000)
      })
    },
    time(value) {
      return `${(`0${value}`).slice(-2)}`
    },
    timer() {
      this.seconds -= 1
      if (this.seconds === 0) {
        this.inProgress = !this.inProgress
        this.btnResendDisable = !this.btnResendDisable
        clearInterval(this.intervalId)
      }
    },
    updateApprovalStatus(status) {
      this.submitDisabled = !status
    },
    resetCompanyRegistrationForm() {
      this.companyRegistrationForm = {
        tenantType: '',
        companyName: '',
        city: '',
        line1: '',
        country: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
        faxNumber: '',
        warehouseName: '',
      }
    },
    resetSubscribedPlan() {
      this.subscribedPlan.planId = null
      this.subscribedPlan.chargeSchedule = ''
    },
    resetUserRegistrationForm() {
      this.userRegistrationForm.firstName = ''
      this.userRegistrationForm.middleName = ''
      this.userRegistrationForm.lastName = ''
      this.userRegistrationForm.password = ''
      this.userRegistrationForm.passwordCheck = ''
      this.userRegistrationForm.loginName = ''
      // We are keeping email for resend activation link
    },
  },
}
</script>

<style lang="scss" scoped>
  .send-message {
    display: block;
    font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
    font-size: 1rem;
    color:#ee1111;
    text-align: justify;
    text-justify: inter-word;
  }
  .resend-message {
    display: block;
    font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
    font-size: 1rem;
    text-align: justify;
    text-justify: inter-word;
  }
  #resendBtn{
    .dx-button-content {
      padding: 4px 6px;
    }
    .dx-button-text {
      color: #ffffff;
    }
  }
  .text-success::before {
    content: '✓ ';
  }

  .text-danger::before {
    content: '✗ ';
  }
</style>
