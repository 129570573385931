<template>
  <div class="auth-wrapper auth-v2">
    <div class="row auth-inner m-0">
      <!--begin:: Brand logo-->
      <b-link class="brand-logo">
        <logo-with-text />
      </b-link>
      <!--end:: Brand logo-->
      <!--begin:: Left Text-->
      <div class="col-md-8 d-lg-flex align-items-center p-5">
        <div class="w-100 d-none d-sm-block d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="require('@/assets/images/pages/register-v2-dark.svg')" alt="Register" />
        </div>
      </div>
      <!--end:: Left Text-->

      <!--begin:: Register-->
      <div class="col-md-4 d-flex align-items-center auth-bg">
        <div class="container-fluid">
          <div class="row">
            <div v-if="isShowRegister === 'waiting'" class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">
                      Please wait
                    </h4>
                    <p class="card-text">
                      Please wait until we verify your service provider...
                    </p>
                  </div>
                </div>
            </div>
            <div v-else-if="isShowRegister === 'showing'" class="col-md-12">
              <div class="card card-developer-meetup align-self-center w-100">
                <div class="mx-lg-auto bg-light rounded p-1 mb-3">
                  <img :src="logo" height="180">
                </div>
                <div class="card-body">
                  <div class="meetup-header d-flex align-items-center">
                    <div class="meetup-day">
                      <h6 class="mb-0">
                        {{ today.month }}
                      </h6>
                      <h3 class="mb-0">
                        {{ today.day }}
                      </h3>
                    </div>
                    <div class="my-auto">
                      <h4 class="card-title mb-0">
                        Welcome to {{ tenant.companyName }}!
                      </h4>
                      <p class="card-text mb-0">
                        Please sign-in to your account and start the adventure
                      </p>
                    </div>
                  </div>
                  <div v-if="tenant.address" class="media mb-2">
                    <div class="media-aside mr-1 align-self-start">
                      <span class="b-avatar badge-light-primary rounded">
                        <span class="b-avatar-custom">
                          <b-icon icon="pin-map-fill" font-scale="1.6" />
                        </span>
                      </span>
                    </div>
                    <div class="media-body">
                      <h6 class="mb-0">
                        Main Office Location
                      </h6>
                      <small>{{ tenant.address }}</small>
                    </div>
                  </div>
                <!--begin:: Register Form-->
                  <register-form v-if="isShowRegister === 'showing'" :tenant-api-key="tenantKey" :register-store-url="registerStoreUrl" />
                <!--end:: Register Form-->
                <div class="divider mb-2">
                  <div class="divider-text">
                    or
                  </div>
                </div>
                <p class="text-center mt-2">
                  <span>Already have an account?</span>
                  <b-link :to="{ name: 'auth-login', params: { tenantKey: tenantKey } }">
                    <span>&nbsp;Sign in instead</span>
                  </b-link>
                </p>
              </div>
            </div>
            </div>
            <div v-else class="col-md-12">
              <div v-if="false" class="card">
                <div class="card-body">
                  <h4 class="card-title">
                    Registration is restricted!
                  </h4>
                  <p class="card-text">
                    Please follow <strong class="text-warning"> the registration link </strong> provided by a service provider. A service provider is the 3PL company that processes your items on your behalf.
                  </p>
                  <p>
                    Or alternatively, you may enter the service provider's  <strong class="text-warning"> Tenant ID </strong>  below. (You can get this information from your service provider.)
                  </p>
                  <dx-util-text-box v-model="tenantId" :on-enter-key="goToForm" class="mb-1" />
                  <dx-util-button class="d-block" text="Submit" type="success" @click="goToForm" />
                </div>
              </div>
              <div v-if="!openCustomerRegistrationForm" class="row p-1">
                  <div class="col-12 text-center">
                    <h3 class="text-warning">
                      "How would you describe yourself?"</h3>
                  </div>
              </div>
              <div v-if="!openCustomerRegistrationForm" class="row justify-content-center m-auto">
                <div class="text-left">
                  <div class="border d-flex rounded card-effect m-1">
                    <div role="button" class="card " @click="openRegistrationForm(TenantTypeEnum.INDIVIDUAL)">
                      <div class="card-header px-1">
                        <div class="card-title">
                          <h3 class="m-0">
                            Individual Merchant</h3>
                          <p class="text-muted m-0" style="font-size: small;">
                            Self-service</p>
                        </div>
                      </div>
                      <div class="card-body py-0">
                        <div>
                          A seller who manages their own inventory and
                          handles all aspects of their sales operations.
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center px-1">
                      <img :src="require('@/assets/images/illustration/individual.svg')" height="80">
                    </div>
                  </div>
                </div>
                <div class="text-left">
                  <div class="border d-flex rounded card-effect m-1">
                    <div role="button" class="card" @click="openRegistrationForm(TenantTypeEnum.SERVING)">
                      <div class="card-header px-1">
                        <div class="card-title">
                          <h3 class="m-0">
                            Logistic Service Provider</h3>
                          <p class="text-muted m-0" style="font-size: small;">
                            3PLs, Prep Centers, Warehouses</p>
                        </div>
                      </div>
                      <div class="card-body py-0">
                        <div>
                          A third-party logistics (3PL) company provides outsourcing logistics,
                          packaging, labeling, and shipping services to its customers.
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center px-1">
                      <img :src="require('@/assets/images/illustration/service-provider.svg')" height="80">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="divider mb-2">
                  <div class="divider-text">
                    or
                  </div>
                </div>
                <p class="text-center mt-2">Go back to
                  <router-link :to="{name: 'auth-login'}">
                  login</router-link>  page
                </p>
                </div>
              </div>
              <div>
                <CustomerRegistrationForm v-show="openCustomerRegistrationForm" :tenant-type="selectedCustomerType"
                  @on-back-to-selections="openCustomerRegistrationForm = false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end:: Register-->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import LogoWithText from '@core/layouts/components/LogoWithText.vue'
import tenantService from '@/http/requests/tenant/tenan.settings'
import moment from 'moment'
import TenantTypeEnum from '@/enums/tenantType.enum'
import tenantApi from '@/http/requests/system/tenantService'
import { Notify } from '@/@robustshell/utils'
import RegisterForm from './register-form/RegisterForm.vue'
import CustomerRegistrationForm from './register-form/CustomerRegistrationForm.vue'
import { useCustomerRegistration } from './register-form/useCustomerRegistration'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const { type, plan } = to.query
      vm.manageRegister(type, plan)
      const { tenantKey } = to.params
      vm.showRegister(tenantKey)
    })
  },
  components: {
    'logo-with-text': LogoWithText,
    RegisterForm,
    CustomerRegistrationForm,
  },
  data() {
    return {
      TenantTypeEnum,
      isShowRegister: 'waiting', // waiting, showing, restricted
      openCustomerRegistrationForm: false,
      selectedCustomerType: {},
      tenantKey: '',
      tenantId: '',
      serviceProvider: '',
      tenant: {
        name: 'psh',
        companyName: 'PrepShipHub',
        address: '',
        phone: '',
        email: '',
      },
    }
  },
  setup() {
    const { setCustomerType, filterPlan, customerPlans } = useCustomerRegistration()
    return {
      setCustomerType, filterPlan, customerPlans,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    today() {
      let day = null
      let month = null
      const today = moment()
      day = today.format('D')
      month = today.format('MMM')
      return {
        day: day,
        month: month,
      }
    },
    registerStoreUrl() {
      return this.$route.query.registerStoreUrl || ''
    },
    logo() {
      let url = ''
      if (this.tenant.tenantLogoUrl) {
        url = this.tenant.tenantLogoUrl
      } else {
        // eslint-disable-next-line import/no-dynamic-require
        url = require(`@/assets/images/logo/logo-${this.tenant.name}.svg`)
      }
      return url
    },
  },
  methods: {
    async showRegister(tenant) {
      if (tenant) {
        const result = await tenantService.checkApiKey(tenant)
        if (result.data) {
          this.serviceProvider = result.data.name
          this.tenantKey = tenant
          this.isShowRegister = 'showing'
          this.getTenantInfo(tenant)
        } else {
          this.tenantKey = ''
          this.isShowRegister = 'restricted'
        }
      } else {
        this.isShowRegister = 'restricted'
      }
    },
    nullCheck(value) {
      return value != null && value !== undefined ? value : ''
    },
    async getTenantInfo(id) {
      if (id) {
        try {
          const result = await tenantApi.getTenantInfo(id)
          let address = ''
          if (result.address) {
            address = `${this.nullCheck(result.address?.city)}, ${this.nullCheck(result.address?.state)} ${this.nullCheck(result.address?.zipcode)}, ${this.nullCheck(result.address?.country)}`
          }
          this.tenant = {
            name: result.tenantId.toLowerCase(),
            companyName: result.companyName,
            address: address,
            tenantLogoUrl: result.tenantUrl,
          }
          const promise = new Promise(resolve => {
            resolve(true)
          })
          return promise
        } catch (err) {
          const promise = new Promise(resolve => {
            resolve(false)
          })
          return promise
        }
      }

      const promise = new Promise(resolve => {
        resolve(false)
      })
      return promise
    },
    goToForm() {
      tenantService.checkTenant(this.tenantId.toUpperCase()).then(res => {
        this.tenantKey = res.data
        this.showRegister(this.tenantKey)
      }).catch(() => {
        Notify.warning('Please enter a valid tenant API key for registration')
      })
    },
    openRegistrationForm(type) {
      this.selectedCustomerType = type
      this.openCustomerRegistrationForm = true
      this.setCustomerType(type.value)
    },
    async manageRegister(type, plan) {
      if (type === undefined || type === null) {
        return
      }
      const customerType = type?.toUpperCase()
      await this.setCustomerType(customerType)
      this.openCustomerRegistrationForm = true
      this.filterPlan(plan)
      if (this.customerPlans.length === 0) {
        this.openCustomerRegistrationForm = false
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.card-effect {
  max-width: 500px;

  /* Set the transition properties for the hover effect */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  /* Set the initial transform and box-shadow properties */
  transform: translateY(0);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

  /* Add the hover effect */
  &:hover {
    transform: translateY(-5px); /* move the card up by 5px on hover */
    box-shadow: 0 4px 16px rgba(56, 137, 243, 0.3) !important; /* add a stronger box-shadow */
  }
}
</style>
